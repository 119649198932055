import { createAuth0Client } from '@auth0/auth0-spa-js';
import { createAuth } from '@ctm/auth';
import { error, redirect } from '@sveltejs/kit';
import { browser } from '$app/environment';
import { goto } from '$app/navigation';
import { getEnv } from '$lib/environments';

let debtor;
if (browser) {
	const urlSearchParams = new URLSearchParams(window.location.search);

	debtor = urlSearchParams.get('d') || undefined;
}

export const disableAuth0 = import.meta.env.VITE_OVERRIDE_CTM_AUTH === 'true';

export const auth = createAuth({
	client() {
		if (!browser) {
			return null;
		}
		return createAuth0Client({
			clientId: getEnv().authClientId,
			domain: getEnv().authDomain,
			authorizationParams: {
				audience: 'https://tripflow',
				scope: 'openid email profile',
				redirect_uri: browser ? location.protocol + '//' + location.host : '/',
				debtor,
			},
			leeway: 60,
		});
	},
	error,
	goto,
	redirect,
	signInPath: '/sign-in',
});
